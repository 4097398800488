<template>
    <div class="visual-canvas">
        <svg class="visual-canvas__svg"
             width="100%"
             height="100%"
             :viewBox="viewBox"
             preserveAspectRatio="xMidYMid slice">
            <rect fill="#000" width="100%" height="100%" />

            <image x="0" y="0" width="100%" height="100%" preserveAspectRatio="none" :xlink:href="render"
                   :class="['visual-canvas__image', {'visual-canvas__image_is_mask': onMask}]"/>
            <image
                x="0" y="0" width="100%" height="100%"
                preserveAspectRatio="none" :xlink:href="render" mask="url(#hole)" />

            <mask id="hole">
                <transition name="fade">
                    <path v-for="mask in masks" :key="mask" :d="mask" fill="white"/>
                </transition>
            </mask>

            <template
                v-for="element in elements">
                <router-link
                    :key="element.id"
                    v-if="!element.reload"
                    class="router-link"
                    v-tooltip.bottom-start="{content: tooltipHtml}"
                    :to="element.id == 403 ? '/parking/' : getLink(element)"
                    :data-element-id="element.id"
                    @click="handleClick(element)"
                >
                    <path
                          class="visual-canvas__path"
                          :d="element.coords"
                          :style="pathStyle"
                          :fill="element.fillColor || 'transparent'"
                          :fill-opacity="element.opacity || 0"
                          @mouseenter="hoverPath(element)"
                          @mouseleave="leaveHoverPath(element)">
                    </path>
                </router-link>

                <a
                    :key="element.id"
                    v-if="element.reload"
                    class="native-link"
                    v-tooltip.bottom-start="{content: tooltipHtml}"
                    :href="getLink(element)"
                >
                    <path
                          class="visual-canvas__path"
                          :d="element.coords"
                          :style="pathStyle"
                          :fill="element.fillColor || 'transparent'"
                          :fill-opacity="element.opacity || 0"
                          @mouseenter="hoverPath(element)"
                          @mouseleave="leaveHoverPath(element)">
                    </path>
                </a>
            </template>
        </svg>

        <Tooltip theme="build" :tooltip="tooltip" v-show="false" ref="foo" />
    </div>
</template>

<script>
import Tooltip from '../Tooltip/Tooltip';

export default {
    components: {Tooltip},
    props     : ['viewBox', 'render', 'elements', 'nextStep', 'theme'],
    data      : () => {
        return {
            onMask     : false,
            masks      : [],
            tooltip    : {},
            tooltipHtml: ' ',
            pathStyle  : {},
            testAB     : false
        };
    },
    watch: {
        tooltip(tooltip) {
            if (!tooltip) {
                this.tooltipHtml = '';

                return;
            }

            setTimeout(() => {
                if (!this.$refs || !this.$refs.foo || !this.$refs.foo.$el) {
                    this.tooltipHtml = '';

                    return;
                }

                this.tooltipHtml = this.$refs.foo.$el.innerHTML;
            }, 0);
        }
    },
    methods: {
        handleClick(element) {
            if (element.id == 403) {
                window.location.reload();
            }
        },
        hoverPath(element) {
            this.reverseFill(element);
            this.masks = [element.coords];
            this.tooltip = element.tooltip;
            this.onMask = true;
        },
        leaveHoverPath(element) {
            this.reverseFill(element);
            this.masks = [];
            this.tooltip = {};
            this.onMask = false;
        },
        getLink(element) {
            if (element.redirect) {
                return element.redirect;
            }

            if (element.redirect === false) {
                return '';
            }

            const link = {
                name  : this.nextStep,
                params: {idComplex: this.$appOptions.complexId}
            };

            // eslint-disable-next-line func-style
            function returnParams(name) {
                link.params[name] = element.id;

                return link;
            }

            if (this.$route.params.idBuilding) {
                link.params.idBuilding = this.$route.params.idBuilding;
            } else {
                return returnParams('idBuilding');
            }

            if (this.$route.params.idSection) {
                link.params.idSection = this.$route.params.idSection;
            } else {
                return returnParams('idSection');
            }

            if (this.$route.params.idFloor) {
                link.params.idFloor = this.$route.params.idFloor;
            } else {
                return returnParams('idFloor');
            }

            return link;
        },

        reverseFill(element) {
            const fill = element.fillColor;
            const opacity = element.opacity;

            if (element.fillColorHover) {
                element.fillColor = element.fillColorHover;
                element.fillColorHover = fill;
            }

            if (element.opacityHover) {
                element.opacity = element.opacityHover;
                element.opacityHover = opacity;
            }
        }
    }
};
</script>

<style lang='scss'>
    @import "./Canvas.scss";
</style>
